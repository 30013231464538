import { createReducer } from '@reduxjs/toolkit';
import { TabBarLayoutActions } from './actions';
import { TabBarLayoutState } from './state';

const initialState: TabBarLayoutState = {
  visitedPages: []
};

export const tabBarLayoutReducer = createReducer(initialState, (builder) => {
  builder.addCase(TabBarLayoutActions.addVisitedPage, (state, { payload: { url } }) => {
    state.visitedPages = [...state.visitedPages, url];
  });
});
