import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@store/types';
import { TabBarLayoutState } from './state';

const selectFeature = (state: AppState): TabBarLayoutState => state.tabBarLayout;

export class TabBarLayoutSelectors {
  public static visitedPages = createSelector(
    selectFeature,
    (state) => state.visitedPages
  );
}
