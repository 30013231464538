import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { TabBar } from './components';
import { TabBarLayoutActions, TabBarLayoutSelectors } from './store';

export function TabBarLayout(): ReactElement {
  const location = useLocation();
  const visitedTabBarPages = useSelector(TabBarLayoutSelectors.visitedPages);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!window.matchMedia('(display-mode: browser)').matches && !visitedTabBarPages.includes(location.pathname)) {
      window.scrollTo(0, -30);
      dispatch(TabBarLayoutActions.addVisitedPage({ url: location.pathname }));
    }

    return () => {
      if (!window.matchMedia('(display-mode: browser)').matches) {
        window.scrollTo(0, -30);
      }
    };
  }, [location.pathname]);

  return (
    <>
      <Outlet />
      <TabBar />
    </>
  );
}
